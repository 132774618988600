<template>
  <div>
    <div class="flex-box">

    <div class="dropdown" v-if="data.length">
      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="width: 150px">
        {{ currentClickedGb }}
      </button>
      <ul class="dropdown-menu" style="height: 600px; overflow-y: scroll">
        <li @click="clickItem('总股本')"><a class="dropdown-item" href="#">总股本</a></li>
        <li v-for="(item, index) of indexList" :key="item+index" @click="clickItem(item)"><a class="dropdown-item" href="#">{{ item }}</a></li>
      </ul>
    </div>
    <div class="dropdown"   v-if="currentClickedGb!=='总股本'">
      <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="width: 150px">
        {{ currentClickedQj }}
      </button>
      <ul class="dropdown-menu">
        <li @click="clickQj('价格区间')"><a class="dropdown-item" href="#">价格区间</a></li>
        <li v-for="(item, index) of dropDownListItem[currentClickedGb]" :key="item+index" @click="clickQj(item)"><a class="dropdown-item" href="#">{{ item }}</a></li>
      </ul>
    </div>
    </div>
  <money-data-table v-if="data.length" :money-data="data" :current-clicked-gb="currentClickedGb" :current-clicked-qj="currentClickedQj"/>
  </div>
</template>

<script>
import MoneyDataTable from "@/components/MoneyDataTable";
import {ref} from "vue";
import {indexList, dropDownListItem, rawData} from "@/data/data";

export default {
  name: "RawDataTable",
  components: {MoneyDataTable},
  setup(){
    const currentClickedGb = ref('总股本')
    const currentClickedQj = ref("价格区间")
    function clickItem (item){
      currentClickedGb.value = item
      currentClickedQj.value="价格区间"
    }

    function clickQj (item){
      currentClickedQj.value = item
    }
    return{
      data: rawData,
      dropDownListItem,
      indexList,
      clickItem,
      currentClickedGb,
      clickQj,
      currentClickedQj
    }
  }
}
</script>

<style scoped>
.upload-button{
  margin-bottom: 5px;
}
.flex-box{
  display: flex;
  gap: 5px;
  margin: 5px 0px;
}
</style>
