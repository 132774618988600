<template>
<div>
  <div v-for="(item,index) of [1,2,3,4,5,6]" :key="item+index">
   <money-data-by-price-table :price-range="item" />
  </div>
</div>
</template>

<script>
import MoneyDataByPriceTable from "@/components/MoneyDataByPriceTable";
export default {
  name: "PriceDataTable",
  components: {MoneyDataByPriceTable}
}
</script>

<style scoped>

</style>
