<template>
  <nav class="navbar navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid">
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" :class="{active:navIndex===0}" aria-current="page" href="#" @click="selectNav(0)">总表</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link" href="#" :class="{active:navIndex===1}"  @click="selectNav(1)">按股本</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" :class="{active:navIndex===2}" @click="selectNav(2)">按价格</a>
          </li>

        </ul>
          <button class="btn btn-outline-success"  @click="triggerInput">上传东方财富数据</button>
          <form id="DataForm">
            <input @change="getFile"
                   style="width: 255px;display: none"
                   id="file" type="file"
            />
          </form>
      </div>
    </div>
  </nav>
</template>

<script>
import readXlsxFile from "read-excel-file";
import {dropDownListItem, fileName, formattedData, indexList, rawData, selectedNavIndex} from "@/data/data";
import { getLimits, getRightLimit} from "@/data/func";
import {ref} from "vue";

export default {
  name: "MainNav",
  setup(){

    async function getFile(event){
      fileName.value =  event.target.files[0].name.split('.')[0]
      rawData.value = await readXlsxFile(event.target.files[0])

      for(const i of rawData.value){
        for (const j of indexList){
          if(formattedData[j] === undefined){
            formattedData[j] = {}
            formattedData[j][1] = []
            formattedData[j][2] = []
            formattedData[j][3] = []
            formattedData[j][4] = []
            formattedData[j][5] = []
            formattedData[j][6] = []
          }
          const gb = parseFloat(i[2])
          const limits = getLimits(j)
          if( gb > limits[0] && gb <= limits[1]){


            const priceRanges = dropDownListItem[j]
            for (let k = 0 ; k < priceRanges.length; k++){
              // if(formattedData[j][k+1] === undefined){
              //   formattedData[j][k+1] = []
              // }
              const rightLimit = getRightLimit(priceRanges[k])
              const price = parseFloat(i[3])
              // console.log(price, rightLimit)
              if(price <= rightLimit){
                // console.log(formattedData[j][k+1] )

                formattedData[j][k+1].push(i)
                break
              }

            }
            break
          }

        }
      }
      // console.log(formattedData)
    }
    function triggerInput(){
      const selectedFile = document.querySelector("input#file")
      selectedFile.click()
    }

    const navIndex = ref(0)
    function selectNav(index){
      navIndex.value = index
      selectedNavIndex.value = index
    }

    return{
      getFile,
      triggerInput,
      selectNav,
      navIndex,
    }
  }

}
</script>

<style scoped>
</style>
