import { json2csv } from 'json-2-csv';

export function getLimits(limitStr){
    const end = limitStr.split("≤")[1]
    const start = limitStr.split("<")[0]
    return [parseFloat(start), parseFloat(end)]
}


export function getRightLimit(limitStr){
    const end = limitStr.split("≤")[1]
    return  parseFloat(end)
}


export async function exportToCsv(filename, data, keys) {
    // content 为接口传回来的值  "\ufeff"是为了解决CSV中文乱码问题
    console.log(data)
   await json2csv(data,(e,csvData)=>{
        const blob = new Blob(["\ufeff" + csvData], {
            type: 'text/csv;charset=utf-8;'
        },{
            keys
        });
// 如果是 IE 浏览器
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

    })

}
