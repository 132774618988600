<template>
  <router-view />
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app{
  padding: 5px;
}
.content {
  margin: 0;
  padding: 0;
}
</style>
