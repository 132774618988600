<template>
<div>
  <div v-for="(item,index) of indexList" :key="item+index">
      <money-data-by-gb-table :gb-str="item" />
  </div>
</div>
</template>

<script>
import {formattedData, indexList} from "@/data/data";
import MoneyDataByGbTable from "@/components/MoneyDataByGbTable";

export default {
  name: "GbDataTable",
  components: {MoneyDataByGbTable},
  setup(){

    return{
      formattedData,
      indexList,
    }
  }
}
</script>

<style scoped>

</style>
