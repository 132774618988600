<template>
  <div>
    <main-nav />
    <raw-data-table v-if="selectedNavIndex===0"/>
    <gb-data-table v-if="selectedNavIndex===1" />
    <price-data-table v-if="selectedNavIndex===2"/>
  </div>
</template>

<script>
import MainNav from "@/views/MainNav";
import {selectedNavIndex} from "@/data/data";
import RawDataTable from "@/views/RawDataTable";
import GbDataTable from "@/views/GbDataTable";
import PriceDataTable from "@/views/PriceDataTable";
export default {
  name: "MainView",
  components: {PriceDataTable, GbDataTable, RawDataTable, MainNav},
  setup(){
    return{
      selectedNavIndex
    }
  }
}
</script>

<style scoped>

</style>
