<template>
  <table class="table table-striped table-hover table-bordered caption-top">
    <caption >
      <div style="display: inline-block; cursor: pointer" @click="prepareDownload('价格区间'+priceRange)" >
        点击下载
        <svg style="width: 15px; height: 15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>download</title><path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" /></svg>

      价格区间 {{ priceRange }}
      </div>
        </caption>
    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">{{ moneyData[0][0] }}</th>
      <th scope="col">{{ moneyData[0][1] }}</th>
      <th scope="col">{{ moneyData[0][2] }}</th>
      <th scope="col">{{ moneyData[0][3] }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item,index) of dataBody" :key="item+index">
      <th scope="row">{{ index+1 }}</th>
      <td>{{ item[0] }}</td>
      <td>{{ item[1] }}</td>
      <td>{{ item[2] }}</td>
      <td>{{ item[3] }}</td>
    </tr>

    </tbody>
  </table>
</template>

<script>
import {computed} from "vue";
import {fileName, formattedData, indexList, rawData} from "@/data/data";
import {exportToCsv} from "@/data/func";
// import {exportToCsv} from "@/data/func";

export default {
  name: "MoneyDataByPriceTable",
  props:{
    priceRange: Number,
  },
  setup(props){
    const dataBody = computed(()=>{
      const returnArr = []
      for (const i of indexList){
        returnArr.push(...formattedData[i][props.priceRange])
      }
      return [...returnArr]
    })
    function prepareDownload(name){

      const csvData = []
      for(const i of dataBody.value){
// console.log(i)
        csvData.push({
          [rawData.value[0][0]]:i[0],
            [rawData.value[0][1]]:i[1],
            [rawData.value[0][2]]:i[2],
            [rawData.value[0][3]]:i[3],

        })
      }
      console.log(rawData.value[0],csvData)
      exportToCsv(name+' '+fileName.value+'.csv',csvData,rawData.value[0])

    }
    return{
      dataBody,
      moneyData:rawData,
      prepareDownload
    }
  }
}
</script>

<style scoped>

</style>
