<template>
  <table class="table table-striped table-hover table-bordered  caption-top">
    <caption>

      <div style="display: inline-block; cursor: pointer" @click="prepareDownload()" >
        点击下载
        <svg style="width: 15px; height: 15px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>download</title><path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" /></svg>

      </div>

    </caption>

    <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">{{ moneyData[0][0] }}</th>
      <th scope="col">{{ moneyData[0][1] }}</th>
      <th scope="col">{{ moneyData[0][2] }}</th>
      <th scope="col">{{ moneyData[0][3] }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item,index) of body" :key="item+index">
      <th scope="row">{{ index+1 }}</th>
      <td>{{ item[0] }}</td>
      <td>{{ item[1] }}</td>
      <td>{{ item[2] }}</td>
      <td>{{ item[3] }}</td>
    </tr>

    </tbody>
  </table>
</template>

<script>
import {computed} from "vue";
import {fileName, rawData} from "@/data/data";
import {exportToCsv} from "@/data/func";

export default {
  name: "MoneyDataTable",
  props:{
    moneyData: Array,
    currentClickedGb: String,
    currentClickedQj: String
  },
  setup(props){
    const header = computed(()=>{
      return props.moneyData[0]
    })

    const body = computed(()=>{
      if(props.currentClickedGb!=='总股本'){

        if(props.currentClickedQj!=='价格区间'){

          const end = props.currentClickedGb.split("≤")[1]
          const start = props.currentClickedGb.split("<")[0]
          const qjEnd = props.currentClickedQj.split("≤")[1]
          const data = props.moneyData
          return  data.filter((v)=>{
            return v[2] > start && v[2] <= end && v[3] <= qjEnd
          }).sort((a,b)=> {
            return a[2] > b[2]
          })

        } else {
          const end = props.currentClickedGb.split("≤")[1]
          const start = props.currentClickedGb.split("<")[0]

          const data = props.moneyData
          return  data.filter((v)=>{
            return v[2] > start && v[2] <= end
          }).sort((a,b)=> {
            return a[2] > b[2]
          })
        }


      }
      return props.moneyData.slice(1)
    })


    function prepareDownload(){

      let name = props.currentClickedGb
      if(props.currentClickedQj === '价格区间'){
        name += ' 全部区间 '
      } else {
        name += ' 价格区间 '+props.currentClickedQj
      }


      const csvData = []
      for(const i of body.value){
// console.log(i)
        csvData.push({
          [rawData.value[0][0]]:i[0],
          [rawData.value[0][1]]:i[1],
          [rawData.value[0][2]]:i[2],
          [rawData.value[0][3]]:i[3],

        })
      }
      console.log(rawData.value[0],csvData)
      exportToCsv(name+' '+fileName.value+'.csv',csvData,rawData.value[0])

    }

    return{
      header,
      body,
      prepareDownload
    }
  }
}
</script>

<style scoped>

</style>
