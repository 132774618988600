import {reactive, ref} from "vue";

export const fileName = ref(undefined)
export const rawData = ref([])
export const selectedNavIndex = ref(0)
export const indexList = [
    "0<T≤0.3",
    "0.3<T≤0.35",
    "0.35<T≤0.4",
    "0.4<T≤0.45",
    "0.4<T≤0.5",
    "0.5<T≤0.55",
    "0.55<T≤0.6",
    "0.6<T≤0.65",
    "0.65<T≤0.7",
    "0.7<T≤0.75",
    "0.75<T≤0.8",
    "0.8<T≤0.85",
    "0.85<T≤0.9",
    "0.9<T≤0.95",
    "0.95<T≤1",
    "1<T≤1.25",
    "1.25<T≤1.5",
    "1.5<T≤2",
    "2<T≤2.5",
    "2.5<T≤3",
    "3<T≤3.5",
    "3.5<T≤4",
    "4<T≤5",
    "5<T≤6",
    "6<T≤7",
    "7<T≤8",
    "8<T≤9",
    "9<T≤10",
    "10<T≤12",
    "12<T≤15",
    "20<T≤50",
]
export const dropDownListItem = {
    "0<T≤0.3": [
        "≤45",
        "≤50",
        "≤55",
        "≤60",
        "≤65",
        "≤70"
    ],
    "0.3<T≤0.35": [
        "≤40",
        "≤45",
        "≤50",
        "≤55",
        "≤60",
        "≤65"
    ],
    "0.35<T≤0.4": [
        "≤35",
        "≤38",
        "≤42",
        "≤46",
        "≤50",
        "≤55"
    ],
    "0.4<T≤0.45": [
        "≤32",
        "≤35",
        "≤38",
        "≤42",
        "≤45",
        "≤50"
    ],
    "0.4<T≤0.5": [
        "≤30",
        "≤32",
        "≤35",
        "≤39",
        "≤42",
        "≤48"
    ],
    "0.5<T≤0.55": [
        "≤27",
        "≤30",
        "≤33",
        "≤36",
        "≤40",
        "≤45"
    ],
    "0.55<T≤0.6": [
        "≤25",
        "≤28",
        "≤31",
        "≤34",
        "≤37",
        "≤40"
    ],
    "0.6<T≤0.65": [
        "≤23",
        "≤25",
        "≤28",
        "≤30",
        "≤33",
        "≤36"
    ],
    "0.65<T≤0.7": [
        "≤20",
        "≤21",
        "≤24",
        "≤26",
        "≤28",
        "≤31"
    ],
    "0.7<T≤0.75": [
        "≤19",
        "≤20",
        "≤22",
        "≤24",
        "≤26",
        "≤29"
    ],
    "0.75<T≤0.8": [
        "≤17",
        "≤19",
        "≤21",
        "≤23",
        "≤25",
        "≤28"
    ],
    "0.8<T≤0.85": [
        "≤16",
        "≤18",
        "≤20",
        "≤22",
        "≤24",
        "≤27"
    ],
    "0.85<T≤0.9": [
        "≤15",
        "≤17",
        "≤19",
        "≤21",
        "≤23",
        "≤26"
    ],
    "0.9<T≤0.95": [
        "≤14",
        "≤16",
        "≤18",
        "≤20",
        "≤21",
        "≤23"
    ],
    "0.95<T≤1": [
        "≤13.5",
        "≤15",
        "≤17",
        "≤18",
        "≤18",
        "≤20"
    ],
    "1<T≤1.25": [
        "≤11.5",
        "≤12.5",
        "≤14",
        "≤15",
        "≤16",
        "≤18"
    ],
    "1.25<T≤1.5": [
        "≤9.5",
        "≤10",
        "≤12",
        "≤12",
        "≤13",
        "≤15"
    ],
    "1.5<T≤2": [
        "≤7",
        "≤7.5",
        "≤8.5",
        "≤9",
        "≤10",
        "≤12"
    ],
    "2<T≤2.5": [
        "≤6",
        "≤7",
        "≤8",
        "≤8.5",
        "≤9",
        "≤10"
    ],
    "2.5<T≤3": [
        "≤4.5",
        "≤5",
        "≤5.5",
        "≤6",
        "≤7",
        "≤8"
    ],
    "3<T≤3.5": [
        "≤4.2",
        "≤4.5",
        "≤5",
        "≤5.5",
        "≤6",
        "≤7"
    ],
    "3.5<T≤4": [
        "≤3.8",
        "≤4",
        "≤4.5",
        "≤4.8",
        "≤5.5",
        "≤6.2"
    ],
    "4<T≤5": [
        "≤3.5",
        "≤3.8",
        "≤4.2",
        "≤4.6",
        "≤5.3",
        "≤6"
    ],
    "5<T≤6": [
        "≤3.2",
        "≤3.5",
        "≤3.9",
        "≤4.2",
        "≤5",
        "≤5.6"
    ],
    "6<T≤7": [
        "≤3",
        "≤3.2",
        "≤3.6",
        "≤3.9",
        "≤4.5",
        "≤5.2"
    ],
    "7<T≤8": [
        "≤2.8",
        "≤3",
        "≤3.5",
        "≤3.6",
        "≤4",
        "≤4.8"
    ],
    "8<T≤9": [
        "≤2.6",
        "≤2.8",
        "≤3.2",
        "≤3.4",
        "≤3.7",
        "≤4.2"
    ],
    "9<T≤10": [
        "≤2.3",
        "≤2.5",
        "≤2.8",
        "≤3",
        "≤3.2",
        "≤3.6"
    ],
    "10<T≤12": [
        "≤1.9",
        "≤2",
        "≤2.5",
        "≤2.5",
        "≤2.6",
        "≤3.1"
    ],
    "12<T≤15": [
        "≤1.7",
        "≤1.8",
        "≤2.2",
        "≤2.2",
        "≤2.5",
        "≤2.8"
    ],
    "20<T≤50": [
        "≤1.4",
        "≤1.5",
        "≤1.8",
        "≤1.8",
        "≤2",
        "≤2.5"
    ],
}
export const formattedData = reactive({})
